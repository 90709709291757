import React, { createContext, useReducer } from 'react'
import moment from 'moment'

const workDays = 250 - 25 - 9
const workedSeconds = workDays * 8 * 60 * 60
const profitRatio = 1.5
const annualSalaryToCostPerSecond = salary => ((salary * profitRatio) / workedSeconds)

const initialState = {
  attendees: {
    cto: {
      count: 0,
      annualSalary: 100000,
      costPerSecond: null,
    },
    architect: {
      count: 0,
      annualSalary: 850000,
      costPerSecond: null,
    },
    leadDev: {
      count: 0,
      annualSalary: 70000,
      costPerSecond: null,
    },
    seniorDev: {
      count: 0,
      annualSalary: 65000,
      costPerSecond: null,
    },
    productOwner: {
      count: 0,
      annualSalary: 55000,
      costPerSecond: null,
    },
  },
  sidebar: {
    visible: true,
  },
  counter: {
    started: null,
    startedAt: null,
    cost: 0,
  }
}

Object.keys(initialState.attendees).forEach(profile => (
  initialState.attendees[profile].costPerSecond = annualSalaryToCostPerSecond(initialState.attendees[profile].annualSalary))
)

const AppContext = createContext()

const reducer = (state = initialState, action) => {
  let count
  let newCost
  let newCounter
  let newAttendee
  let newAttendees
  let newSidebar
  let newState

  switch (action.type) {
    case 'reset':
      return initialState
    case 'incrementAttendee':
      count = Math.max(0, state.attendees[action.attendee].count + 1)
      newAttendee = Object.assign({}, state.attendees[action.attendee], { count })
      newAttendees = Object.assign({}, { ...state.attendees }, { [action.attendee]: newAttendee })
      newState = Object.assign({}, { ...state }, { attendees: newAttendees })

      return newState
    case 'decrementAttendee':
      count = Math.max(0, state.attendees[action.attendee].count - 1)
      newAttendee = Object.assign({}, state.attendees[action.attendee], { count })
      newAttendees = Object.assign({}, { ...state.attendees }, { [action.attendee]: newAttendee })
      newState = Object.assign({}, { ...state }, { attendees: newAttendees })

      return newState
    case 'hideSidebar':
      newSidebar = Object.assign({}, { ...state.sidebar }, { visible: false })
      newState = Object.assign({}, { ...state }, { sidebar: newSidebar })

      return newState
    case 'showSidebar':
      newSidebar = Object.assign({}, { ...state.sidebar }, { visible: true })
      newState = Object.assign({}, { ...state }, { sidebar: newSidebar })

      return newState
    case 'startCounter':
      newCounter = {
        started: true,
        startedAt: moment(),
        cost: 0,
      }
      newState = Object.assign({}, { ...state }, { counter: newCounter })

      return newState
    case 'stopCounter':
      newCounter = Object.assign({}, { ...state.counter }, { started: false })
      newState = Object.assign({}, { ...state }, { counter: newCounter })

      return newState
    case 'setCost':
      newCost = action.cost
      newCounter = Object.assign({}, { ...state.counter }, { cost: newCost })
      newState = Object.assign({}, { ...state }, { counter: newCounter })

      return newState
    default:
      return state
  }
}

function AppContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <AppContext.Provider value={{ state, dispatch }}>{props.children}</AppContext.Provider>
  )
}

export { AppContext, AppContextProvider }

