export default {
  locale: 'fr',
  AnimatedCost: {
    currency: '€',
    start: 'start',
    stop: 'stop',
  },
  Attendees: {
    header: 'Qui participe à la réunion ?',
    reset: 'reset',
    cto: 'CTO',
    architect: 'Architect',
    leadDev: 'Lead dev',
    seniorDev: 'Senior dev',
    productOwner: 'Product owner',
  },
  MeetingCost: {
  },
  ResultSummary: {
    result: '{{duration}} de réunion vous a couté {{cost}} €',
    corresponding: 'À quoi correspond cette somme ?',
    worth: 'Votre temps est précieux et peux faire des heureux, gare au gaspillage.',
    restart: 'restart',
  },
}
