import moment from 'moment'

export const calculateCost = (startedAt, attendees) => {
    const diff = moment().diff(startedAt, 'seconds')

    let attendeesCostPerSeconds = 0
    Object.keys(attendees).forEach(key => attendeesCostPerSeconds += attendees[key].costPerSecond * attendees[key].count)

    return diff * attendeesCostPerSeconds
}
