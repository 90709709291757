import React, { useContext } from 'react'
import { useTranslate } from 'react-translate'
import { Button } from 'semantic-ui-react'
import AnimatedNumber from 'animated-number-react'
import { AppContext } from '../contexts/AppContext'
import '../styles/AnimatedCost.css'

const AnimatedCost = () => {
  const t = useTranslate('AnimatedCost')
  const { state, dispatch } = useContext(AppContext)

  const handleStartTimer = () => {
    dispatch({ type: 'hideSidebar' })
    dispatch({ type: 'startCounter' })
  }

  const handleStopTimer = () => {
    dispatch({ type: 'stopCounter' })
  }

  return (
    <div className="AnimatedCost">
      <div className="AnimatedCost__cost">
        <AnimatedNumber value={state.counter.cost} duration={1000} formatValue={value => value.toFixed(2)} />
        <span> {t('currency')}</span>
      </div>

      <div className="AnimatedCost__toggle">
        {!state.counter.started && <Button color="teal" onClick={handleStartTimer}>{t('start')}</Button>}
        {state.counter.started && <Button onClick={handleStopTimer}>{t('stop')}</Button>}
      </div>
    </div>
  )
}

export default AnimatedCost
