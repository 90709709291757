import React, { useContext } from 'react'
import { useTranslate } from 'react-translate'
import { Button, Card, Header, Image } from 'semantic-ui-react'
import { AppContext } from '../contexts/AppContext'
import '../styles/ResultSummary.css'

const ResultSummary = () => {
  const t = useTranslate('ResultSummary')
  const { state, dispatch } = useContext(AppContext)
  const duration = state.counter.startedAt && state.counter.startedAt.toNow(true)

  const handleRestartTimer = () => {
    dispatch({ type: 'reset' })
  }

  return (
    <div className="ResultSummary">
      <Header className="ResultSummary__header" size='huge'>{t('result', { cost: Math.ceil(state.counter.cost), duration })}</Header>
      <Header className="ResultSummary__subheader" size='small'>{t('corresponding')}</Header>

      <Card.Group className="ResultSummary__cards" centered textAlign="left">
        <Card>
          <Card.Content>
            <Image floated='right' size='tiny' src='https://react.semantic-ui.com/images/wireframe/image.png' />
            <Card.Header>Lorem Ipsum</Card.Header>
            <Card.Meta>Dolor sit amet</Card.Meta>
            <Card.Description>
              Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
            </Card.Description>
          </Card.Content>
        </Card>
        <Card>
          <Card.Content>
            <Image floated='right' size='tiny' src='https://react.semantic-ui.com/images/wireframe/image.png' />
            <Card.Header>Muspi Merol</Card.Header>
            <Card.Meta>Tema tis rolod</Card.Meta>
            <Card.Description>
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
            </Card.Description>
          </Card.Content>
        </Card>
      </Card.Group>

      <Header className="ResultSummary__footer" size='medium'>{t('worth')}</Header>
      <Button className="ResultSummary__restart" color="teal" onClick={handleRestartTimer}>{t('restart')}</Button>
    </div>
  )
}

export default ResultSummary
