import React, { useContext } from 'react'
import { useTranslate } from 'react-translate'
import { Button, Grid, Menu, Sidebar } from 'semantic-ui-react'
import { AppContext } from '../contexts/AppContext'
import '../styles/Attendees.css'

const Attendees = function() {
  const t = useTranslate('Attendees')
  const { state, dispatch } = useContext(AppContext)

  return (
    <Sidebar
      className="Attendees"
      as={Menu}
      animation="scale down"
      icon="labeled"
      inverted
      vertical
      visible={state.sidebar.visible}
      width="wide"
    >
      <Menu.Item><Menu.Header className="Attendees__header">{t('header')}</Menu.Header></Menu.Item>
      {Object.keys(state.attendees).map(attendee => (
        <Menu.Item key={attendee}>
          <Grid columns={2} verticalAlign="middle">
            <Grid.Row>
              <Grid.Column textAlign="left">
                <span>{t(attendee)}</span>
              </Grid.Column>
              <Grid.Column textAlign="right">
                <Button color="black" onClick={() => dispatch({ type: 'incrementAttendee', attendee })}>+</Button>
                <span>{state.attendees[attendee].count}</span>
                <Button color="black" onClick={() => dispatch({ type: 'decrementAttendee', attendee })}>-</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Menu.Item>
      ))}
      <Menu.Item>
        <Button color="black" onClick={() => dispatch({ type: 'reset' })}>{t('reset')}</Button>
      </Menu.Item>
    </Sidebar>
  )
}

export default Attendees
