import React from 'react'
import { TranslatorProvider } from 'react-translate'
import { Sidebar } from 'semantic-ui-react'
import moment from 'moment'
import 'moment/locale/fr'
import translations from './translations/fr'
import Attendees from './components/Attendees'
import MeetingCost from './components/MeetingCost'
import { AppContextProvider } from './contexts/AppContext'
import 'semantic-ui-css/semantic.min.css'
import './styles/App.css'

moment.locale('fr')

const App = () => (
  <div className="App">
    <AppContextProvider>
      <TranslatorProvider translations={translations}>
        <Sidebar.Pushable>
          <Attendees />
          <MeetingCost />
        </Sidebar.Pushable>
      </TranslatorProvider>
    </AppContextProvider>
  </div>
)

export default App
