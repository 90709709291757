import React, { useContext } from 'react'
import { Grid, Sidebar } from 'semantic-ui-react'
import { calculateCost } from '../tools/costCalculator'
import useInterval from '../tools/useInterval'
import { AppContext } from '../contexts/AppContext'
import AnimatedCost from './AnimatedCost'
import ResultSummary from './ResultSummary'
import '../styles/MeetingCost.css'

const MeetingCost = () => {
  const { state, dispatch } = useContext(AppContext)

  useInterval(() => {
    dispatch({ type: 'setCost', cost: calculateCost(state.counter.startedAt, state.attendees) })
  }, state.counter.started ? 5000 : null)

  return (
    <Sidebar.Pusher className="MeetingCost">
      <Grid columns={1} verticalAlign="middle">
        <Grid.Row className="MeetingCost__row">
          <Grid.Column textAlign="center" verticalAlign="middle">
            {false !== state.counter.started && <AnimatedCost />}
            {false === state.counter.started && <ResultSummary />}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Sidebar.Pusher>
  )
}

export default MeetingCost
